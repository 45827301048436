import React from "react";
import {Link} from "react-router-dom";
import UserIcon from "../frontend/UserIcon";
import "../scss/admin.scss";

export default function AdminBase({title,children}){
  React.useEffect(()=>{
    document.title = "HubHawaii Server Admin";
  },[]);
  return (
    <div id="holder" className="admin">
      <div id="header">
        <div className="container">
          <h1>Survey Admin</h1>
          <br/><Link to="/admin/">Admin Home</Link> | <Link to="/admin/survey/new">New Survey</Link>
          <UserIcon/>
        </div>
      </div>
      <div id="content">
        {children}
      </div>

    </div>
  );
}
