import React from "react";
import AdminBase from "./AdminBase";
import {Link,useParams} from "react-router-dom"
import SurveyAdminForm from "./SurveyAdminForm";
import SurveyAdminChart from "./SurveyAdminChart";
import axios from "axios";
import * as config from "../config";

export default function SurveyAdminResults(props){
  const {surveyID} = useParams();

  const [data,setData] = React.useState();
  const [loadedJS,setLoadedJS] = React.useState(false);

  React.useEffect(()=>{
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://survey.hubhawaii.com/public/js/survey.min.js";
//    script.src = "http://survey.localhost:8003/public/js/survey.min.js";
    script.onload = function(){
      setLoadedJS(true);
    };

    document.head.appendChild(script);

    axios.get(config.API_URL+"id/"+surveyID+"/results")
    .then(res=>{
      setData(res.data);
    });





    //For head
    document.head.appendChild(script);
  },[]);

  React.useEffect(()=>{
    if(!data || !loadedJS){
        return;
    }
    loadCharts();
  },[data,loadedJS]);

  const loadCharts = () => {
    data.survey.questions.forEach((q,questionIndex)=>{
      if(q.textarea){
        return false;
      }
      new window.surveyChart("chart_"+questionIndex,{
        type:"pie",
        axis:"x",
        admin:true,
        questions:JSON.stringify(q),
        answers:JSON.stringify(data.answers[questionIndex]),
      });
    });

  };

  const percentage = (answers,total) => {
    answers = answers || 0;
    total = total || 0;

    return total == 0 ? 0 : Math.floor((parseInt(answers)/parseInt(total))*100);

  }

  const colors = ["red","blue","green","orange","yellow","red","blue","green","orange","yellow"];


  const resultComponents = data && data.survey.questions.map((q,questionIndex)=>{
    let total = data.answers[questionIndex] ? data.answers[questionIndex].total : 0;

    if(q.textarea){
      let textResponses = Object.keys(data.answers[questionIndex].choices).map(r=>{
        return (<li>{r}</li>);
      });
      return (<div className="questionResult">
            <br/><small>Total Responses: {total}</small>

            <p>
              <ul style={{textAlign:"left"}}>
                {textResponses}
              </ul>
            </p>

          </div>);
    }

    let choiceAnswers = q.choices ? q.choices.map((choice,choiceIndex)=>{
      let tally = data.answers[questionIndex] ? data.answers[questionIndex].choices[choiceIndex] : 0;

      return (<>
        <br/>{choice}: {tally || 0} ({percentage(tally,total)}%)

      </>);
    }) : (<></>);

    return (
      <div className="questionResult">

        <div id={`chart_${questionIndex}`} style={{width:500,maxWidth:"100%",display:"block",marginLeft:"auto",marginRight:"auto"}}></div>
        <br/><small>Total Votes: {total}</small>

        <p>
          {choiceAnswers}
        </p>

      </div>
    );
  });

  return (<AdminBase title="Survey Results">
      {data && (<>
        <h1>{data.survey.title}</h1>
        Participants: {data.count}

        {resultComponents}

      </>)}
    </AdminBase>);
};
