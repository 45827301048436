import React from "react"
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Survey from "./frontend/Survey";
import About from "./frontend/About";
import SurveyAdmin from "./admin/SurveyAdmin";
import SurveyAdminNew from "./admin/SurveyAdminNew";
import SurveyAdminEdit from "./admin/SurveyAdminEdit";
import SurveyAdminResults from "./admin/SurveyAdminResults";
import SurveyAdminDetails from "./admin/SurveyAdminDetails";
import Home from "./frontend/Home";

import "./scss/forms.scss";

import {UserContextProvider} from "./context/userContext.js";
import {TemplateContextProvider} from "./context/templateContext.js";

import AuthRoute from "./admin/AuthRoute";

function App() {

  return (
    <UserContextProvider>
      <TemplateContextProvider>
        <Router>
    			<Switch>
    				<Route path="/" exact>
    					<Home/>
    				</Route>
    				<Route path="/about" exact>
    					<About/>
    				</Route>
            <Route path="/survey/:surveyID" component={Survey} exact/>
            <AuthRoute path="/admin/" exact>
              <SurveyAdmin/>
            </AuthRoute>
            <AuthRoute path="/admin/survey/new/" exact>
              <SurveyAdminNew/>
            </AuthRoute>
            <AuthRoute path="/admin/survey/:surveyID" exact>
              <SurveyAdminEdit/>
            </AuthRoute>

            <AuthRoute path="/admin/survey/:surveyID/details" exact>
              <SurveyAdminDetails/>
            </AuthRoute>

            <AuthRoute path="/admin/survey/:surveyID/results" exact>
              <SurveyAdminResults/>
            </AuthRoute>
    			</Switch>
    		</Router>
      </TemplateContextProvider>
    </UserContextProvider>
  );
}

export default App;
