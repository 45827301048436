import React from "react";
import {useParams} from "react-router-dom";
import TemplateBase from "./TemplateBase";
import SurveyView from "./SurveyView";
import Loader from "./Loader";
import SurveyLogin from "./SurveyLogin";
import {userContext} from "../context/userContext";
import {templateContext} from "../context/templateContext";
import axios from "axios";
import * as config from "../config";
import {updateHeight} from "../helpers";


export default function About(props){




  return (<TemplateBase title="Survey Technical Explanation" description="<a href='javascript:history.go(-1);'>Back</a>">
      <div className="container about">

        <h2>Why We Require Authentication:</h2>
        <p>To avoid multiple responses from each user, we require participants to login via one of the following services:</p>
        <ul>
          <li>HubHawaii.com</li>
          <li>Google</li>
          <li>Facebook</li>
          <li>Amazon</li>
        </ul>
        <p>While a user may participate in the survey multiple times, their answers will only be recorded once per email address and/or IP address.  If a user has already participated in the survey, they will receive a notification upon login alerting that their previous responses will be voided.</p>

        <div className="divider"></div>
        <h2>How We Verify Users</h2>
        <p>When a user logs in through one of the above services, we receive the following information:</p>
        <ul>
          <li>Unique Identifier from the Authentication Service</li>
          <li>User’s Email Address</li>
          <li>User’s IP Address</li>
        </ul>

        <p>To ensure all responses are kept confidential, while limiting responses to (1) per unique email address and IP address, we utilize a one-way encryption technique before saving the user’s email address to the database.   This technique will "hash" and “salt” the email address, outputting a random string of characters that can not be decoded back to the original e-mail address.   This technique is commonly used for securely storing passwords.</p>

        <p>When a user logs in, the e-mail address is encrypted, and the database is queried to ensure a matching hash code is not already stored.   If the hash is found in the database, the user has already participated in the survey, and their new answers will replace their previous answers. </p>
        <p>The email address is then verified through a third-party service which checks whether the email address is valid, and not part of any known SPAM/Bot databases.   Email addresses that are found to be blacklisted are prohibited from participating in the survey.</p>
        <p>The IP Address is also queried, to help mitigate multiple responses from one device.  If a matching IP address is found, the survey has already been completed from that device, and previous answers will be voided.</p>

      

        <p>By using these techniques, we are able to better ensure there is only one response per email address, one response per device, the e-mail address is associated with a real person, and no personal-identifying information is associated with the responses.</p>

        <div className="divider"></div>
        <h2>What is Stored in our Database</h2>
        <ul>
          <li>
            E-Mail Address Hash
            <ul><li>Example: <pre>695ddccd984217fe8d79858dc485b67d66489145afa78e8b27c1451b27cc7a2b</pre></li></ul>
          </li>
          <li>
            IP Address
            <ul><li>Example: <pre>192.168.0.1</pre></li></ul>
          </li>
          <li>
            Survey Responses in JSON format
            <ul><li>Example: <pre>&#123;"0":["0"],"1":["0"],"2":["1"],"3":["1"],"4":["0"],"5":["0"]&#125;</pre></li></ul>
          </li>
          <li>
            Survey ID
            <ul><li>Example: <pre>v1t8u4b5o4a2p</pre></li></ul>
            
          </li>
        </ul>

        <div className="divider"></div>
        <p>This survey system is hosted separately from MauiNow.com, BigIslandNow.com and KauaiNowNews.com via the https://survey.hubhawaii.com/ subdomain.    No third-party cookies are used for the purpose of advertising, no information is sold, no personal-identifying information is stored, and all responses are kept anonymous.</p>

      </div>


    </TemplateBase>);
}
