import React from "react";
import {userContext} from "../context/userContext";

export default function UserIcon(props){
  const [user,setUser,logout] = React.useContext(userContext);
  const [expand,setExpand] = React.useState(false);

  return (
    <>
      {user ? (<>
        {user.fromApp ? (<></>) : (<div id="userIcon" class={`${expand ? `expand` : ``}`}>
          <img src={user.picture} onClick={()=>setExpand(!expand)}/>
          <div className="menu">
            <small>Logged in as</small>
            {user.name}
            <a onClick={logout} className="logout">LOGOUT</a>
          </div>

        </div>)}

      </>) : (<></>)}
    </>
  )
};
