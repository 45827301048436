import React from "react";
import AdminBase from "./AdminBase";
import {Link} from "react-router-dom"
import SurveyAdminForm from "./SurveyAdminForm";

export default function SurveyAdmin(props){
  return (<AdminBase title="New Survey">
      <SurveyAdminForm/>
    </AdminBase>);
};
