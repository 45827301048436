import React from "react";
import AdminBase from "./AdminBase";
import axios from "axios";
import Loader from "../frontend/Loader";
import * as config from "../config";

export default function SurveyAdminForm(props){

  const {data} = props;
  const [formData,setFormData] = React.useState(data || {questions:[{choices:["",""]}]});
  const [image,setImage] = React.useState(false);
  const [loading,setLoading] = React.useState(false);

  const updateFormData = (e) => {
    setFormData(prevData=>({
      ...prevData,
      [e.target.name]:e.target.value
    }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    let AJAXURL = config.API_URL + "new/";
    if(formData.id){
      AJAXURL = config.API_URL + "edit/"+formData.id;
    }


    axios.post(AJAXURL,{
      data:JSON.stringify({...formData,image:image})
    }).then(res=>{
      setLoading(false);
      window.location = "/admin/survey/"+res.data.id+"/details";
    });

    return false;
  }

  const addChoice = (e,questionIndex) => {
    e.preventDefault();
     formData.questions[questionIndex].choices.push("");
    setFormData({...formData});
  };

  const addQuestion = (e) => {
    e.preventDefault();

    formData.questions.push({question:"",choices:["",""]});
    setFormData({...formData});
  };


  const updateQuestion = (e,questionIndex) => {
    setFormData(prevData=>{
      prevData.questions[questionIndex].question = e.target.value;
      return {...prevData};
    });
  }

  const updateQuestionType = (e,questionIndex) => {
    setFormData(prevData=>{
      prevData.questions[questionIndex].multiple = e.target.checked ? true : false;
      return {...prevData};
    });
  }

  const updateQuestionRequirement= (e,questionIndex) => {
    setFormData(prevData=>{
      prevData.questions[questionIndex].required = e.target.checked ? true : false;
      return {...prevData};
    });
  }

  const updateQuestionTextarea = (e,questionIndex) => {
    setFormData(prevData=>{
      prevData.questions[questionIndex].textarea = e.target.checked ? true : false;
      return {...prevData};
    });
  }

  const removeQuestion = (e,questionIndex) => {
    e.preventDefault();
    formData.questions.splice(questionIndex,1);

      setFormData({...formData});
  }


  const removeChoice = (e,questionIndex,choiceIndex) => {
    e.preventDefault();
    formData.questions[questionIndex].choices.splice(choiceIndex,1);

      setFormData({...formData});
  }

  const updateChoice = (e,questionIndex,choiceIndex) => {
    setFormData(prevData=>{
      prevData.questions[questionIndex].choices[choiceIndex] = e.target.value;
      return {...prevData};
    });
  };

  const updateImage = (e) => {
    var file = e.target.files[0];
    let imageData = new FormData();
    imageData.append('file',file);
    axios.post(config.API_URL+"uploadImage",imageData,{
      headers: {
       'Content-Type': 'multipart/form-data'
     }
    }).then(res=>{
      if(res.data.imageUrl){
        setFormData({
          ...formData,
          imageUrl:res.data.imageUrl
        });
      }
    });

  }

  let questionComponents = formData.questions.map((q,questionIndex)=>{
    let choiceComponents = q.choices.map((c,choiceIndex)=>{
      return (<li key={`choice_${questionIndex}_${choiceIndex}`}><label>
        <input type="text" placeholder={`Choice ${choiceIndex+1}`} style={{width:"65%",float:"left"}} name="choice" onChange={(e)=>updateChoice(e,questionIndex,choiceIndex)} value={c}/>
         <a href="#" style={{color:"red",lineHeight:"40px",marginLeft:"10px"}} onClick={(e)=>removeChoice(e,questionIndex,choiceIndex)}>Remove</a>
         <div style={{clear:"both"}}></div>
      </label> </li>)
    });

  return (<>

    <label key={`question_${questionIndex}`}>
      <a href="#" onClick={(e)=>removeQuestion(e,questionIndex)} style={{textTransform:"uppercase",color:"red",float:"right"}}>Remove Question</a>
      Question
      <input type="text" placeholder="Question" style={{fontWeight:"bold"}} name="question" value={q.question} onChange={(e)=>updateQuestion(e,questionIndex)}/>
      <label className="radio" style={{lineHeight:"24px"}}>
        <input type="checkbox" checked={q.textarea} name="textarea" onChange={(e)=>updateQuestionTextarea(e,questionIndex)}/>
        Fill-in-the-blank Question Response
      </label>
      {!q.textarea && (<label className="radio" style={{lineHeight:"24px"}}>
        <input type="checkbox" checked={q.multiple} name="multiple" onChange={(e)=>updateQuestionType(e,questionIndex)}/>
        Allow Multiple Choice
      </label>)}
      <label className="radio" style={{lineHeight:"24px"}}>
        <input type="checkbox" checked={q.required} name="required" onChange={(e)=>updateQuestionRequirement(e,questionIndex)}/>
        Require Response
      </label>

      {!q.textarea && (
        <ul>
          {choiceComponents}
          <li key={`choice_${questionIndex}_new`}><a href="#" onClick={(e)=>addChoice(e,questionIndex)} style={{marginLeft:"12px",lineHeight:"40px"}}>Add Another Choice</a></li>
        </ul>
      )}

    </label>

    <hr/>

    </>);
  });

  return (<form onSubmit={submitForm} className="adminForm">
    {(data && data.id) && (<big style={{textAlign:"center",fontWeight:"bold",padding:"10px"}}><b style={{color:"red"}}>IMPORTANT:</b> Modifying an existing survey can compromise results.   Please do not remove questions or choices if the survey is already receiving responses.</big>)}
    <Loader loading={loading} text="Saving Survey"/>
    <fieldset>
      <legend>Survey Details</legend>
      <label className="radio" style={{lineHeight:"24px"}}>
        <input type="checkbox" checked={formData.expired} name="expired" onChange={(e)=>{ if(e.target.checked){ setFormData({...formData,expired:"true"}); } else { setFormData({...formData,expired:"false"}); } }}/>
        De-Activate Survey
      </label>
      <label>
        Survey Title
        <input type="text" name="title" onChange={updateFormData} value={formData.title || ""}/>

      </label>
      <label>
        Website
        <select name="website" value={formData.website} onChange={updateFormData}>
          <option value=""></option>
          <option value="mauinow" >Maui Now</option>
        </select>

      </label>
      <label>
        Survey Description
        <textarea name="description" onChange={updateFormData} value={formData.description || ""}/>
      </label>
      <label>
        Survey Completed Message
        <textarea name="completed" onChange={updateFormData} value={formData.completed || ""}/>
      </label>
      <label>
        Social Media Share Image (Recommended Size 1200px by 650px)
        <input type="file" name="image" onChange={updateImage}/>
        {formData.imageUrl && (
          <img src={formData.imageUrl} style={{maxWidth:"100%",maxHeight:300}}/>
        )}
      </label>
    </fieldset>

    <fieldset>
      <legend>Questions</legend>
      {questionComponents}
      <a href="#" style={{display:"block",textAlign:"center",padding:"10px"}} onClick={(e)=>addQuestion(e)}>+ Add Another Question</a>
    </fieldset>

      <button type="submit">{formData.id ? `Edit` : `Create`} Survey</button>

    </form>);
};
