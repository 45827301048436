import React from "react";
import "../scss/style.scss";
import "../scss/siteStyles.scss";
import UserIcon from "./UserIcon";
import {templateContext} from "../context/templateContext";

export default function TemplateBase({children,title,description}){

  const [template,setTemplate] = React.useContext(templateContext);
  const [logo,setLogo] = React.useState(false);
  const [website,setWebsite] = React.useState(false);

  React.useEffect(()=>{
    switch(template){
      case "mauinow":
        import("../images/mauinowSurveyLogo.png").then(image=>{
          setLogo(image.default);
          setWebsite("https://mauinow.com/");
        });

      break;
    }
  },[template]);

  return (
    <div id="holder" className={`${template}`}>
      <div id="header">
        <div class="container">
          {logo && <a href={website} target="_blank"><img src={logo} className="logo"/></a>}
          <UserIcon/>
          <h2>{title}</h2>
          {description && (<div className="description" dangerouslySetInnerHTML={{__html:description}}></div>)}
        </div>
      </div>
        {children}


    </div>
  );
}
