import React from "react";
import * as config from "../config";



class FacebookLogin extends React.Component{


  render(){
    const {appId} = this.props;

    const triggerLogin = () => {

        let currentPage = window.location.href.toString();
        currentPage = currentPage.split("#");
        currentPage = encodeURIComponent(currentPage[0]);
        let redirectURI = encodeURIComponent(config.AUTH_URL+"softAuth/amazon/");
        window.location = "https://www.amazon.com/ap/oa?client_id="+appId+"&response_type=code&redirect_uri="+redirectURI+"&scope=profile&state="+currentPage;

    };

    return(<button type="button" style={{
      display:"block",
      border: "0px",
      borderRadius:"3px",
      boxShadow: "rgba(0, 0, 0, 0.5) 0px 1px 2px",
      color: "#fff",
      cursor: "pointer",
      fontSize: "19px",
      margin: "5px",
      width: "calc(100% - 10px)",
      overflow: "hidden",
      padding: "0px 10px",
      userSelect: "none",
      height: "50px",
      background: 'rgb(249, 174, 50)'
    }}><div style={{
      alignItems: "center",
      display: "flex",
      height: "100%"
    }}
      onClick={triggerLogin}
    >
        <div style={{display: "flex", justifyContent: "center", minWidth: "26px"}}>
<svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" width="26px" height="26px" viewBox="0 0 24 24"><path d="M13.958 10.09c0 1.232.029 2.256-.591 3.351-.502.891-1.301 1.438-2.186 1.438-1.214 0-1.922-.924-1.922-2.292 0-2.692 2.415-3.182 4.7-3.182v.685zm3.186 7.705c-.209.189-.512.201-.745.074-1.052-.872-1.238-1.276-1.814-2.106-1.734 1.767-2.962 2.297-5.209 2.297-2.66 0-4.731-1.641-4.731-4.925 0-2.565 1.391-4.309 3.37-5.164 1.715-.754 4.11-.891 5.942-1.095v-.41c0-.753.06-1.642-.383-2.294-.385-.579-1.124-.82-1.775-.82-1.205 0-2.277.618-2.54 1.897-.054.285-.261.567-.549.582l-3.061-.333c-.259-.056-.548-.266-.472-.66.704-3.716 4.06-4.838 7.066-4.838 1.537 0 3.547.41 4.758 1.574 1.538 1.436 1.392 3.352 1.392 5.438v4.923c0 1.481.616 2.13 1.192 2.929.204.287.247.63-.01.839-.647.541-1.794 1.537-2.423 2.099l-.008-.007zm3.559 1.988c-2.748 1.472-5.735 2.181-8.453 2.181-4.027 0-7.927-1.393-11.081-3.706-.277-.202-.481.154-.251.416 2.925 3.326 6.786 5.326 11.076 5.326 3.061 0 6.614-1.214 9.066-3.494.406-.377.058-.945-.357-.723zm.67 2.216c-.091.227.104.32.31.147 1.339-1.12 1.685-3.466 1.411-3.804-.272-.336-2.612-.626-4.04.377-.22.154-.182.367.062.337.805-.096 2.595-.312 2.913.098.319.41-.355 2.094-.656 2.845z" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
        </div>
          <div style={{width: "10px"}}></div>
          <div style={{textAlign: "left",width:"100%"}}>Log in with Amazon</div>
        </div>
      </button>);
  }
}

export default FacebookLogin;
