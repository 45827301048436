import React from "react";
import config from "../config";
import {userContext} from "../context/userContext";
import "../scss/hubhawaiilogin.scss";
import axios from "axios";
import Loader from "./Loader";
import {createButton} from "react-social-login-buttons";

import HubHawaiiIcon from "../images/hubHawaiiIcon.png";
import HubHawaiiLogo from "../images/hubhawaiiLogo.png";

export default function HubHawaiiLogin(props){

  const [user,setUser] = React.useContext(userContext);
  const [email,setEmail] = React.useState(false);
  const [password,setPassword] = React.useState(false);
  const [loading,setLoading] = React.useState(false);
  const [showLogin,setShowLogin] = React.useState(false);

  const validateUser = (e) => {
    e.preventDefault();
    var formData = new FormData();
    setLoading(true);
    formData.append("username",email);
    formData.append("password",password);
    
    axios({
      method:"POST",
      url:config.HUBHAWAII_LOGIN_API,
      data:formData
    }).then(res=>{
        processLogin(res.data);
        setLoading(false);
      });

    return false;

  };



  const processLogin = (data) => {
    if(data.authenticated == false){
      alert("Authentication Failed");
    }
    else{
      setUser({
        name:data.profile.name,
        picture:data.profile.picture,
        email:data.profile.email,
        admin:data.profile.admin,
        provider:"hubhawaii"
      },true);
    }
  }

  return (<>
    <Loader loading={loading} text="Authenticating"/>
    {!showLogin && (<button className="hubHawaiiLoginButton" onClick={()=>setShowLogin(true)}>
      <div style={{display:"flex",alignItems:"center",height:"100%"}}>

          <div style={{justifyContent:"center",minWidth:"26px",display:"flex"}}>
            <img src={HubHawaiiIcon} style={{width:"26px",height:"26px"}}/>
          </div>
          <div style={{width:"10px"}}></div>

          <div style={{width:"100%",textAlign:"left"}}>
            Log in with Hub Hawaii
          </div>

      </div>
    </button>)}
    {showLogin && (<form onSubmit={validateUser} className="hubHawaiiLogin">
      <img src={HubHawaiiLogo} className="hhLogo"/>
      <label>
        Username or E-Mail Address
        <input type="text" onChange={(e)=>setEmail(e.target.value)}/>
      </label>
      <label>
        Password
        <input type="password" onChange={(e)=>setPassword(e.target.value)}/>
      </label>
      <a href="https://hubhawaii.com/register/" target="_blank" className="registerLink">Create a Hub Hawaii Account</a>
      <button type="submit">Login</button>
      <div style={{clear:"both"}}></div>
    </form>)}
  </>);


};
