import React from "react";
import {useParams} from "react-router-dom";
import TemplateBase from "./TemplateBase";
import SurveyView from "./SurveyView";
import Loader from "./Loader";
import SurveyLogin from "./SurveyLogin";
import {userContext} from "../context/userContext";
import {templateContext} from "../context/templateContext";
import axios from "axios";
import * as config from "../config";
import {updateHeight} from "../helpers";


export default function Survey(props){
  //match.params.
  let {surveyID} = useParams();
  const [user,setUser] = React.useContext(userContext);
  const [template,setTemplate] = React.useContext(templateContext);
  const [data,setData] = React.useState({});
  const [loading,setLoading] = React.useState(false);
  const [expired,setExpired] = React.useState(false);



  React.useEffect(()=>{
    setLoading(true);
    axios.get(config.API_URL+"id/"+surveyID)
    .then(res=>{
      setData(res.data);
      if(res.data.expired == "true"){
        setExpired(true);
      }
      setTemplate(res.data.website);
      setLoading(false);
      document.title = res.data.title;
    });

    var hash = window.location.hash;
    if(hash){
      hash = hash.replace("#","");
      
      try{
        var hashData = atob(hash);
        var userData = JSON.parse(hashData);
        console.log(userData);
        if(userData.email){
          setUser(userData);

          updateHeight();

        } else if(userData.error){
          alert(userData.message);
        }
        let uri = window.location.href;
        var clean_uri = uri.substring(0,uri.indexOf("#"));
        window.history.replaceState({},document.title, clean_uri);
      }
      catch(e){
        console.log("CHECK APP HASH",hash);
        const [appKey,userid,appKey2] = hash.split("::");
        // http://localhost:3000/survey/g4z8h5s8u2e1v#app::123::80299268
        if(appKey == "app" && appKey2 == "80299268" && userid){
          setUser({
            fromApp:true,
            email:userid,
            
          })
        }
      }


    }

    updateHeight();
  },[]);

  React.useEffect(()=>{

  },[user]);

  if(expired){
    return (<TemplateBase title={data.title} description={data.description}>
        <Loader loading={loading} text="Loading Survey"/>
        <div>This Survey has Expired</div>


      </TemplateBase>);
  }

  return (<TemplateBase title={data.title} description={data.description}>
      <Loader loading={loading} text="Loading Survey"/>

     {user ? (<>
       <div id="contentInvisible">
        <SurveyView surveyID={surveyID}/>
       </div>
     </>) : (<div id="content">
      <h2>To take the survey, start by verifying you're human:</h2>

      <SurveyLogin/>
     </div>)}


    </TemplateBase>);
}
