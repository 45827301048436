import React from "react";
import FacebookLogin from "./FacebookLogin";
import HubHawaiiLogin from "./HubHawaiiLogin";
import GoogleLogin from "./GoogleLogin";
import AmazonLogin from "./AmazonLogin";
import InstagramLogin from "./InstagramLogin";
import {userContext} from "../context/userContext";
import {updateHeight} from "../helpers";
import {useParams} from "react-router-dom";


export default function SurveyLogin(props){
  let {surveyID} = useParams();

  const [user,setUser] = React.useContext(userContext);
  const [socialBrowser,setSocialBrowser] = React.useState(false);

  React.useEffect(()=>{
      if(navigator.userAgent.includes("Instagram") || navigator.userAgent.includes("FBAV") || navigator.userAgent.includes("FBAN")){
        setSocialBrowser(true);
      }

  },[]);

  const onLogin = (user) => {
    setUser({
      email:user._profile.email,
      name:user._profile.name,
      picture:user._profile.profilePicURL,
      provider:user._provider
    });
    updateHeight();
  };

  return (<div className="login">
      <div className="disclaimer">
         This step is necessary to verify you are not a bot.   Your responses will remain anonymous.   For more information on how this system works, <a href="/about">click here</a>.

      </div>
        <HubHawaiiLogin/>
        <FacebookLogin
           provider="facebook"
           appId="237467944978410"

        />


        <GoogleLogin
          provider="google"
          appId="181171957877-aaoo975vlgq437r2pssj7jjv4c87rpjl.apps.googleusercontent.com"
        />


       <AmazonLogin
         provider="amazon"
         appId="amzn1.application-oa2-client.731d62c3c6b54f1eaf0a6029f6419db8"

        />



    </div>);
}
