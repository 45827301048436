import React from "react";
import {updateHeight} from "../helpers";
export const userContext = React.createContext();

export function UserContextProvider({children}){
	const [user,setUserState] = React.useState(false);

  const setUser = (userData,remember=false) => {
    if(remember){
    //  localStorage.setItem('user',JSON.stringify(user));
    }
    setUserState(userData);
  }

  const logout = () => {
		let uri = window.location.href;
		var clean_uri = uri.substring(0,uri.indexOf("#"));
		window.history.replaceState({},document.title, clean_uri);
    localStorage.setItem('user',false);
    setUserState(false);
		updateHeight();
  }

  React.useEffect(()=>{
  //  var savedUser = localStorage.getItem('user');
  //  if(savedUser){
  //    savedUser = JSON.parse(savedUser);
  //    setUserState(savedUser);
  //  }


  },[]);


	return (
		<userContext.Provider value={[user,setUser,logout]}>
			{children}
		</userContext.Provider>

	)
}
