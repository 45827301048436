import React from "react";
import SocialLogin from "react-social-login";
import { InstagramLoginButton } from "react-social-login-buttons";


class InstagramLogin extends React.Component{
  render(){
    const {children,triggerLogin,...props} = this.props;
    return(<InstagramLoginButton  onClick={triggerLogin} {...props}>
      {children}
    </InstagramLoginButton>);
  }
}

export default SocialLogin(InstagramLogin)
