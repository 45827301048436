import React from "react";

export const templateContext = React.createContext();

export function TemplateContextProvider({children}){
	const [template,setTemplate] = React.useState(false);



	return (
		<templateContext.Provider value={[template,setTemplate]}>
			{children}
		</templateContext.Provider>

	)
}
