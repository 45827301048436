module.exports = {
  updateHeight:function(){

    const sendHeight = () => {
      var body = document.body,
      html = document.documentElement;
      var height = Math.max( body.scrollHeight, body.offsetHeight,
                         html.clientHeight, html.scrollHeight, html.offsetHeight );

      window.parent.postMessage({action:"iframeHeight",height:height},"*");
    };
    sendHeight();
    setTimeout(()=>{
      sendHeight();
    },100);
    setTimeout(()=>{
      sendHeight();
    },1000);
  },
  convertDate:function(ts){
    var date = new Date(ts);
    var months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return months[date.getMonth()] + " " + date.getDate() + ", "+ date.getFullYear();

  }
}
