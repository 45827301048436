import React from "react";
import AdminBase from "./AdminBase";
import {Link,useParams} from "react-router-dom"
import SurveyAdminForm from "./SurveyAdminForm";
import axios from "axios";
import * as config from "../config";

export default function SurveyAdminEdit(props){
  const {surveyID} = useParams();

  const [data,setData] = React.useState();

  React.useEffect(()=>{
    axios.get(config.API_URL+"id/"+surveyID)
    .then(res=>{
      setData(res.data);
    });
  },[]);

  return (<AdminBase title="New Survey">
      {data && <SurveyAdminForm data={data}/>}
    </AdminBase>);
};
