import React from "react";
import {Link,useParams} from "react-router-dom"
import Loader from "./Loader";
import Popup from "./Popup";
import axios from "axios";
import * as config from "../config";
import {userContext} from "../context/userContext";
import {updateHeight} from "../helpers";

import "../scss/survey.scss";

export default function SurveyView(props){
  const {surveyID} = props;

  const [data,setData] = React.useState(false);
  const [taken,setTaken] = React.useState(false);
  const [user] = React.useContext(userContext);
  const [answers,setAnswers] = React.useState({});
  const [complete,setComplete] = React.useState(false);
  const [loading,setLoading] = React.useState(false);
  const [focusQuestion,setFocusQuestion] = React.useState(0);
  const [disabled,setDisabled] = React.useState(false);
  const [missing,setMissing] = React.useState(false);

  React.useEffect(()=>{
    setLoading(true);

    axios.post(config.API_URL+"id/"+surveyID+"/user",{
      email:user.email
    })
    .then(res=>{
      setTaken(res.data.taken);
      setData(res.data.survey);
      setLoading(false);
      updateHeight();



    });
  },[]);

  React.useEffect(()=>{
    if ((window.innerHeight) >= document.body.offsetHeight) {
      if(missing || focusQuestion > 0){

        var questionOffset = document.querySelector(".question").getBoundingClientRect();

        document.querySelector("body").scrollTo({top:questionOffset.top+document.querySelector("body").scrollTop-10,behavior:"smooth"});
      }
    }
  },[missing,focusQuestion]);

  React.useEffect(()=>{
    if(!data) return;
    checkResponse();
  },[data,answers]);


  React.useEffect(()=>{
    if(!data) return;
    checkResponse();
    setMissing(false);
    updateHeight();
  },[focusQuestion]);

  const updateAnswers = (e) => {
    const questionIndex = e.target.getAttribute("data-questionIndex");
    const inputs = document.querySelectorAll(".input"+questionIndex);

    const questionAnswers = [];
    inputs.forEach(i=>{
      if(i.checked){
        questionAnswers.push(i.value);
        document.querySelector(".choice_"+questionIndex+"_"+i.value).classList.add("selected");
      }
      else{
        document.querySelector(".choice_"+questionIndex+"_"+i.value).classList.remove("selected");
      }

    });



    answers[questionIndex] = questionAnswers;

    setAnswers({...answers});

  };

  const nextQuestion = (e) => {
    e.preventDefault();

    if(disabled){
      setMissing(true);
        return false;
    }

    setFocusQuestion(focusQuestion+1);

  }

  const updateQuestionResponse = (e) => {
    var questionIndex = e.target.getAttribute("data-questionIndex");

    if(e.target.value == ""){
      answers[questionIndex] = false;
    }
    else
    {
      answers[questionIndex] = [e.target.value];
    }
    setAnswers({...answers});

  };

  const checkResponse = () => {

    if(data.questions[focusQuestion].required){
      if(answers[focusQuestion] && answers[focusQuestion].length > 0){
        setDisabled(false);
        setMissing(false);

      }
      else{
        setDisabled(true);
      }
    }
    else{
      setDisabled(false);
      setMissing(false);
    }



  }

  const submit = (e) => {
    e.preventDefault();

    if(disabled){
      setMissing(true);
      return false;
    }

    setLoading(true);

    axios.post(config.API_URL+"id/"+surveyID+"/save",{
      ...user,
      answers:JSON.stringify(answers)
    })
    .then(res=>{
      setComplete(true);
      setLoading(false);
    });
    return false;

  }

  const questionComponents = data && data.questions.map((q,questionIndex)=>{
    let inputType = q.multiple ? "checkbox" : "radio";
    let choiceComponents = q.choices.map((c,choiceIndex)=>{

      if(c == ""){
        return (<></>);
      }

      return (
        <label className={`choice_${questionIndex}_${choiceIndex}`}>
          <input type={inputType} className={`input${questionIndex}`} value={choiceIndex} onClick={updateAnswers} data-questionIndex={questionIndex} name={`question_${questionIndex}`}/>
           {c}
        </label>
      );
    });

    return (<fieldset className={`${questionIndex == focusQuestion && `focus`} ${questionIndex > focusQuestion && `right`}`}>

      <big className="question">
        <small>Question {questionIndex+1} of {data.questions.length}</small>
        {q.question}
      </big>
      {q.textarea && (
        <textarea maxlength="150" placeholder="Type your response here..." onKeyUp={updateQuestionResponse} data-questionIndex={questionIndex}></textarea>
      )}

      {missing && (<div className="error">Please Select an Option:</div>)}
      {!q.textarea && choiceComponents}

      {questionIndex < (data.questions.length-1) && (
        <button className={`next ${disabled ? `disabled` : ``}`} onClick={nextQuestion}>
          Next Question
          <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Arrow Forward</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"/></svg>
        </button>
      )}

      {questionIndex > 0 && (
        <button className="previous" onClick={(e)=>{ e.preventDefault(); setFocusQuestion(focusQuestion-1)}}>
          <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Arrow Back</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M244 400L100 256l144-144M120 256h292"/></svg>
          Previous
        </button>
      )}

      {questionIndex == (data.questions.length-1) && (
        <button className={`complete ${disabled ? `disabled` : ``}`} type="submit">
          <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Checkmark Circle</title><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"/></svg>
          Submit
        </button>
      )}
    </fieldset>)
  });

  return (<>
    <Loader loading={loading}/>
    {data ? (<>
      <Popup show={taken} close="Continue Retaking Survey">
        It looks like you already took this survey.   Would you like to continue re-taking this questionaire?
      </Popup>


      <form onSubmit={submit} className="survey">

        {complete ? (
          <fieldset className="focus completed">

            <big>
              <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Checkmark Circle</title><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"/></svg>

              <span dangerouslySetInnerHTML={{__html:data.completed}}></span>
            </big>
          </fieldset>
        ) : questionComponents}
      </form>
    </>) : (<>
      Loading...
    </>)}

  </>);
};
