import React from "react";
import "../scss/popup.scss";

export default function Popup({show,children,close}){

  const [showPopup,setShowPopup] = React.useState(show);

  React.useEffect(()=>{
    setShowPopup(show);
  },[show]);


  return (<div className={`popup ${showPopup ? `show` : ``}`}>
    <div className="vCenter">
      <div className="modal">
        {children}

        {close ? (<button className="button" onClick={()=>setShowPopup(false)}>{close}</button>) : (
          <div className="popupClose" onClick={()=>setShowPopup(false)}>&times;</div>
        )}
      </div>
    </div>
  </div>)
}
