import React from "react";
import {Link} from "react-router-dom"
import axios from "axios";
import Loader from "../frontend/Loader";
import * as config from "../config";
import {convertDate} from "../helpers";

export default function SurveyAdminList(props){

  const [list,setList] = React.useState([]);
  const [loading,setLoading] = React.useState(false);

  React.useEffect(()=>{
    axios.get(config.API_URL+"list")
    .then(res=>{
      setList(res.data);
    })
  },[]);

  const duplicate = (surveyID) => {
    setLoading(true);
    axios.post(config.API_URL+"id/"+surveyID+"/duplicate")
    .then(res=>{
      window.location = "/admin/survey/"+res.data.id;
    });

  };

  const listComponents = list.map(l=>{
    return (<li key={`survey_${l.id}`}>
       <Link className="control" to={`/admin/survey/${l.id}/`}>
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Edit Survey</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M364.13 125.25L87 403l-23 45 44.99-23 277.76-277.13-22.62-22.62zM420.69 68.69l-22.62 22.62 22.62 22.63 22.62-22.63a16 16 0 000-22.62h0a16 16 0 00-22.62 0z"/></svg>
       </Link>
       <a className="control" href={`/s/${l.id}`}>
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Survey Link</title><path d="M208 352h-64a96 96 0 010-192h64M304 160h64a96 96 0 010 192h-64M163.29 256h187.42" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="36"/></svg>
       </a>
       <Link className="control" to={`/admin/survey/${l.id}/results`}>
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Survey Results</title><path d="M32 32v432a16 16 0 0016 16h432" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><rect x="96" y="224" width="80" height="192" rx="20" ry="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><rect x="240" y="176" width="80" height="240" rx="20" ry="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><rect x="383.64" y="112" width="80" height="304" rx="20" ry="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
       </Link>
       <div className="control" onClick={()=>duplicate(l.id)}>
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Duplicate Survey</title><rect x="128" y="128" width="336" height="336" rx="57" ry="57" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
       </div>
       <Link to={`/admin/survey/${l.id}/details`}><b>{l.title}</b></Link>
       <br/><small>Created on {convertDate(l.timeAdded)}</small>
    </li>)
  });

  return (<>
    <Loader loading={loading} text="Duplicating Survey"/>
    <ul className="surveyList">
    {listComponents}
    </ul>
  </>);
};
