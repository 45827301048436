import React from "react";
import AdminBase from "./AdminBase";
import {Link,useParams} from "react-router-dom"
import SurveyAdminForm from "./SurveyAdminForm";
import axios from "axios";
import * as config from "../config";

export default function SurveyAdminDetails(props){
  const {surveyID} = useParams();

  const [data,setData] = React.useState();

  React.useEffect(()=>{
    axios.get(config.API_URL+"id/"+surveyID)
    .then(res=>{
      setData(res.data);
    });
  },[]);

  return (<AdminBase title="Survey Details">
      {data && (<>
        {data.imageUrl && (<center><img src={data.imageUrl} style={{display:"block",width:"400px",maxWidth:"100%",margin:"10px",marginLeft:"auto",marginRight:"auto"}}/></center>)}
        <h2>{data.title}</h2>
        <p style={{textAlign:"center",display:"block"}}>{data.description}</p>
        <p style={{textAlign:"center"}}>
          <b><Link to={`/admin/survey/${data.id}/results`}>SURVEY RESULTS</Link> | <Link to={`/admin/survey/${data.id}`}>EDIT SURVEY</Link></b>
        </p>
        <p style={{textAlign:"center"}}>
          SURVEY LINK:
          <br/><b><a href={`https://survey.hubhawaii.com/s/${surveyID}`} target="_blank">https://survey.hubhawaii.com/s/{surveyID}</a></b>
        </p>




      </>)}
    </AdminBase>);
};
