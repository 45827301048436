import React from "react";
import AdminBase from "./AdminBase";
import SurveyAdminList from "./SurveyAdminList";
import {Link} from "react-router-dom"

export default function SurveyAdmin(props){

  

  return (<AdminBase>

      <SurveyAdminList/>
    </AdminBase>);
};
