import React from "react";
import "../scss/loader.scss";

export default function Loader({loading,text}){
  return (<div className={`loader ${loading ? `show` : ``}`}>
    <div style={{display:"inline-block"}}>
      <div class="box">
        <div class="loadercontainer">
          <span class="circle"></span>
          <span class="circle"></span>
          <span class="circle"></span>
          <span class="circle"></span>
        </div>
      </div>
      <br/>{text ? text : `LOADING...`}
    </div>
  </div>);
}
