import React from "react";
import * as config from "../config";



class FacebookLogin extends React.Component{


  render(){
    const {appId} = this.props;

    const triggerLogin = () => {

        let currentPage = window.location.href.toString();
        currentPage = currentPage.split("#");
        currentPage = encodeURIComponent(currentPage[0]);
        let redirectURI = encodeURIComponent(config.AUTH_URL+"softAuth/facebook/");
        //window.location = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id="+appId+"&scope=profile%20email&response_type=code&state="+currentPage+"&redirect_uri="+redirectURI+"&flowName=GeneralOAuthFlow";
        window.location = "https://www.facebook.com/v12.0/dialog/oauth?client_id="+appId+"&redirect_uri="+redirectURI+"&scope=email,public_profile&state="+currentPage;

    };

    return(<button type="button" style={{
      display:"block",
      border: "0px",
      borderRadius:"3px",
      boxShadow: "rgba(0, 0, 0, 0.5) 0px 1px 2px",
      color: "#fff",
      cursor: "pointer",
      fontSize: "19px",
      margin: "5px",
      width: "calc(100% - 10px)",
      overflow: "hidden",
      padding: "0px 10px",
      userSelect: "none",
      height: "50px",
      background: 'rgb(59, 89, 152)'
    }}><div style={{
      alignItems: "center",
      display: "flex",
      height: "100%"
    }}
      onClick={triggerLogin}
    >
        <div style={{display: "flex", justifyContent: "center", minWidth: "26px"}}>
<svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 90 90"><g><path d="M90,15.001C90,7.119,82.884,0,75,0H15C7.116,0,0,7.119,0,15.001v59.998   C0,82.881,7.116,90,15.001,90H45V56H34V41h11v-5.844C45,25.077,52.568,16,61.875,16H74v15H61.875C60.548,31,59,32.611,59,35.024V41   h15v15H59v34h16c7.884,0,15-7.119,15-15.001V15.001z" fill="#FFFFFF"></path></g></svg>
        </div>
          <div style={{width: "10px"}}></div>
          <div style={{textAlign: "left",width:"100%"}}>Log in with Facebook</div>
        </div>
      </button>);
  }
}

export default FacebookLogin;
